<template>
  <div id="news" class="main__wrapper news__details">
    <div class="header__back-content" @click="$router.go(-1)">
      <svg-icon class="icon-arrow-back" name="arrow-back" />
      <span class="">{{ $t('main.back') }}</span>
    </div>

    <div class="news__header-wrapper">
      <h1 class="title-h1 news__title main__header">
        {{ $t(`profile.news`) }}
      </h1>
      <!-- <div class="news__tags-wrapper">
        <div
          class="news__tag"
          :class="{ news__tag_active: curTag === item }"
          v-for="(item, idx) in getTags"
          :key="item"
          @click="getNewsByTag(item)"
        >
          <template v-if="idx === 0">
            {{ $t('main.all_news') }}
          </template>
          <template v-else>
            {{ item }}
          </template>
        </div>
      </div> -->
    </div>
    <ul class="news__container">
      <li
        v-for="(item, idx) in getNewsData"
        :key="`${item.uuid}${idx}`"
        class="news__items custom-link"
        @click="goToNewsDetails(item.uuid)"
      >
        <img :src="item.file" alt="news" class="news__items_img" />
        <h5 class="subtitle">
          {{ item.created_at }}
        </h5>
        <p>{{ item.name }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      curTag: 'all_news',
    };
  },

  async created() {
    await this.$store.dispatch('getNewsTableData');
    await this.$store.dispatch('getAllTags');
  },

  computed: {
    ...mapGetters(['getNewsData', 'getAllTags']),

    getTags() {
      if (!!this.getAllTags) {
        return ['all_news', ...this.getAllTags];
      }
    },
  },

  methods: {
    goToHome() {
      this.$router.push({ name: 'landing-page' });
    },
    goToNewsDetails(id) {
      this.$router.push({ name: 'news-details', params: { id } });
    },
    async getNews() {
      await this.$store.dispatch('getNewsTableData');
    },

    getNewsByTag(tag) {
      this.curTag = tag;
      let payload = tag === 'all_news' ? null : tag;
      this.$store.dispatch('getNewsTableData', payload);
    },
  },
};
</script>

<style lang="sass" scoped>
.news__details
  background-color: white

.news__header-wrapper
  display: flex

.news__tags-wrapper
  max-width: 100%
  display: flex
  flex-wrap: wrap
  max-height: 105px
  overflow: hidden
  margin-left: 30px

.news__title
  margin-bottom: 4rem

.news__container
  display: flex
  gap: 4rem
  flex-wrap: wrap
  background-color: white

.news__items
  border-bottom: 2px solid $black
  width: 30%
  padding-bottom: 16px
  @include s
    width: 100%

  .news__items_img
    max-width: 100%
    height: 180px
.subtitle
  padding-top: 1.6rem
  padding-bottom: 1.6rem

.news__tag
  margin-right: 10px
  color: $gray_active
  cursor: pointer

.news__tag:hover, .news__tag_active
  text-decoration: underline
  color: $black

.header__back-content
  display: flex
  justify-content: flex-start
  align-items: center
  font-size: 1.6rem
  gap: 1.2rem
  color: $black
  cursor: pointer
  margin-bottom: 40px
</style>

